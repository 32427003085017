import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index.vue'
import Home from '@/views/home/index.vue'
import Change from '@/views/change/index.vue'
import Contact from '@/views/contact/index.vue'
import Exchange from '@/views/exchange/index.vue'

Vue.use(VueRouter)

const routes = [
    {
      path: '/',
      name: 'Home',
      component: Index,
      redirect: '/home', // 默认重定向到 /home
      children: [
        { path: 'home', component: Home },
        { path: 'change', component: Change },
        { path: 'contact', component: Contact },
        { path: 'exchange', component: Exchange }
      ]
    }
  ];

const router = new VueRouter({
  routes
})

export default router
