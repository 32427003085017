<template>
	<div class="home">
		<!-- <div style="display: flex;justify-content: center;"> -->
			<div class="home_img">
			<!-- <img src="@/assets/1.jpg" /> -->
		</div>

		<div class="home_img">
			<img src="@/assets/home/coin.jpg" />
		</div>

		<div class="center_top">
			<p>​小女孩希望提供一個優質的兌換貨幣服務</p>
			<p>相對不會將成本壓力加在客人身上</p>
			<p>​力求將價格控制在最便宜優惠</p>
			<p>​節省每位客人保貴的時間！</p>
		</div>

		<div class="center_bottom">
			<p>​我們為你提供</p>
			<p class="DA">不收手續費兌換</p>
			<p class="DA">不設兌換最低額</p>
			<p class="A8">歡迎即日預留貨幣</p>
			<p class="A8">快D掃QR CODE</p>
			<p class="A8">預留</p>
			<p class="A8">↓↓↓</p>
			<p>
				<router-link to="/exchange">
					<span class="AA">即時查看最新匯率</span>
				</router-link>
			</p>
		</div>
		​


		<div class="down_img">
			<img src="@/assets/home/down.png" />
		</div>

		<bottom></bottom>
	</div>
</template>

<script>
	import bottom from '@/components/bottom.vue'
	import axios from 'axios'
	export default {
		components: {
			bottom
		},
		created() {
			axios({
				method: 'get',
				url: 'https://api.xiaonvh.com/app/views/savePageViews/0'
			})
		}
	}
</script>

<style lang="less" scoped>
	.home {
		width: 100%;
		height: auto;
		display: flex;
		flex-direction: column;
		align-items: center;

		.home_img {
			width: 395px;
			height: auto;

			img {
				width: 100%;
			}
		}

		.center_top {
			padding-top: 1vh;
			text-align: center;
		}

		.center_bottom {
			text-align: center;
			font-weight: 600;
			line-height: 12px;

			.DA {
				color: #da4444;
			}

			.A8 {
				color: #a82e2e;
			}

			.AA {
				color: #aaaaaa;
			}

			a {
				text-decoration: none;
			}

			.router-link-active {
				text-decoration: none;
			}
		}

		.down_img {
			margin-top: 6vh;
			margin-bottom: 6vh;
			width: 1036px; //86vw;

			img {
				width: 100%;
			}
		}
	}

	// 手机
	@media screen and (max-width: 420px) {
		.home {
			.home_img {
				width: 90vw !important;
			}
		}
	}

	@media screen and (max-width: 1042px) {
		.down_img {
			width: 86vw !important;
		}
	}
</style>
