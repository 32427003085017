<template>
	<div class="contact">
		<div class="code">
			<img src="@/assets/contact/chat.png" />
		</div>

		<!-- 谷歌地图 -->
		<!-- <gmap-map
            :center="{ lng: 114.167762, lat: 22.286977 }"
            :zoom="15"
            style="width: 100%; height: 100%"
        ></gmap-map> -->
		<!-- 地图 -->
		<div class="map">
			<gmp-map center="22.28553581237793,114.15411376953125" zoom="14" map-id="DEMO_MAP_ID">
				<gmp-advanced-marker position="22.28553581237793,114.15411376953125" title="My location"></gmp-advanced-marker>
			</gmp-map>


			<!-- <baidu-map @ready="handleMapReady" class="bm-view" :center="{ lng: 114.167762, lat: 22.286977 }" :zoom="15">
				<bm-marker :position="{ lng: 114.167762, lat: 22.286977 }" :dragging="true" animation="BMAP_ANIMATION_BOUNCE">
					<bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
				</bm-marker>
			</baidu-map> -->
		</div>

		<bottom></bottom>
	</div>
</template>

<script>
	import bottom from '@/components/bottom.vue'
	import axios from 'axios'
	export default {
		components: {
			bottom
		},

		created() {
			axios({
				method: 'get',
				url: 'https://api.xiaonvh.com/app/views/savePageViews/0'
			})
		},
		methods: {
			handleMapReady({ BMapGL, map }) {
				map.setLang('en');
			}
		}
	}
</script>

<style lang="less" scoped>
	gmp-map {
		height: 100%;
	}

	.contact {
		width: 100%;
		height: auto;
		display: flex;
		flex-direction: column;
		align-items: center;

		.code {
			width: 1036px; //86vw;

			img {
				width: 100%;
			}
		}

		.map {
			width: 1036px; //86vw;
			height: 600px;
			margin-top: 4vh;
			margin-bottom: 4vh;

			.bm-view {
				width: 100%;
				height: 100%;
			}
		}
	}

	@media screen and (max-width: 1042px) {
		.code {
			width: 86vw !important;
		}

		.map {
			width: 86vw !important;
			height: 400px !important;
		}
	}
</style>