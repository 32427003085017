var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ fixed: _vm.isFixed }},_vm._l((_vm.navItems),function(item){return _c('span',{key:item.id},[_c('router-link',{attrs:{"to":item.path}},[_c('span',{staticClass:"label",style:({
                    color:
                        _vm.activeItemId === item.id
                            ? _vm.isFixed
                                ? '#a73b47'
                                : '#d94d5d'
                            : _vm.isFixed
                            ? '#fff'
                            : '#999999'
                }),on:{"click":function($event){return _vm.setActiveItem(item.id)}}},[_vm._v(" "+_vm._s(item.label)+" ")])]),_c('span',{class:'show' + item.id},[_c('router-link',{attrs:{"to":"/exchange"}},[_c('span',{staticClass:"huilv"},[_vm._v("最新匯率")])])],1)],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }