<template>
	<div class="change">
		<div>
			<p>小女孩很響往去旅行,每次去都會買好多野用好多錢</p>
			<p>
				但無奈每次格價兌換貨幣好似去銀行會比較貴,有時侯找換店平D,但又無存貨
			</p>
			<p>
				所以我提供番1D小貼士比大家,等大家去旅行前,唔洗辛苦又格價又搵地方換錢
			</p>
		</div>

		<div class="A8">
			<p>1.香港的找換店門庭若市,不過大多只針對外國遊客或者大陸自由行</p>
			<p>匯率也頗高,不建議在街上隨便找兌換店。</p>
			<p>無論是香港或者外地機場,找換店的匯率也很高</p>
			<p>
				​所以如非必要,還是在香港的銀行或者專門給香港人的兌換店兌匯較好。
			</p>
		</div>

		<div class="A8">
			<p>2.找到合適的銀行或找換店,可先致電去問清楚貨幣是否有存貨</p>
			<p>否則即使格好了價,到步也沒有貨幣可以兌換會浪費太多時間。</p>
		</div>

		<div class="A8">
			<p>3.近年很多銀行提款卡都支援海外提款,每筆交易收到幾十元手續費。</p>
			<p>有人說先在港兌幾萬日圓元,到了日本按情況再用提款機。</p>
			<p>當然,這只可適用於你去東京及大阪這些大城市。</p>
			<p>當你到了比較近郊的地方,簡單得如東京的深大寺</p>
			<p>很近市區的地方,去一去鬼太郎茶屋</p>
			<p>想在車站附近提款,不一定如你所願。</p>
			<p>所以不要過份相信提款卡服務,免得影響了旅行的時間及樂趣。</p>
		</div>
		​
		<div class="A8">
			<p>4. 各外幣兌換攻略</p>
			<p>各地最抵兌換方法</p>
			<p>台灣:到步後,以銀聯卡於當地提款</p>
			<p>南韓:到步後,以銀聯卡於當地提款或於明洞的兌換店兌換</p>
			<p>日本:在港時預先兌換</p>
			<p>泰國:在港時預先兌換或於當地兌換店兌換</p>
			<p>歐洲等地:在港時預先兌換</p>
		</div>

		<bottom></bottom>
	</div>
</template>

<script>
	import bottom from '@/components/bottom.vue'
	import axios from 'axios'
	export default {
		components: {
			bottom
		},
		created() {
			axios({
				method: 'get',
				url: 'https://api.xiaonvh.com/app/views/savePageViews/0'
			})
		}
	}
</script>

<style lang="less" scoped>
	.change {
		width: 100%;
		height: auto;
		display: flex;
		flex-direction: column;
		align-items: center;

		>div {
			font-size: 14px;
			font-weight: 600;
			text-align: center;
		}

		.A8 {
			color: #a82e2e;
			margin-top: 4vh;
		}

		>div:last-child {
			margin-top: 4vh;
		}

		p {
			padding-left: 3vw;
			padding-right: 3vw;
		}
	}
</style>