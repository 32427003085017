import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from 'axios'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';


import BaiduMap from 'vue-baidu-map'

Vue.use(BaiduMap, {
  ak: 'RYMokL6cpKDquOCg9gmoM0QwdaPkU7n6'
})


// import * as VueGoogleMaps from 'vue2-google-maps'

// Vue.use(VueGoogleMaps, {
//   load: {
//     key: 'AIzaSyAPcMaQARRUqZ9hWrxoMmM9nKkYaEUYaLc',
//     libraries: 'places' // 可根据需要添加其他库
//   }
// })

Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
