var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Phone",style:({
        height: _vm.isshow ? '144px' : '60px',
        color: _vm.isFixed ? '#ffffff' : '#999999',
        backgroundColor: _vm.isFixed ? '#D94D5D' : '#ffffff'
    })},[_c('div',{staticClass:"more"},[_c('div',{staticClass:"image",on:{"click":_vm.onshow}},[_c('img',{attrs:{"src":_vm.isFixed
                        ? require('@/assets/more_w.png')
                        : require('@/assets/more_r.png')}})])]),_c('div',{staticClass:"show",style:({ display: _vm.isshow ? 'block' : 'none' })},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.leright),expression:"leright"}],staticClass:"out_box"},[_c('div',[_c('span',{staticClass:"label",style:({
                        color:
                            _vm.activeItemId === 1
                                ? _vm.isFixed
                                    ? '#a73b47'
                                    : '#d94d5d'
                                : _vm.isFixed
                                ? '#fff'
                                : '#999999'
                    }),on:{"click":_vm.moveToRight}},[_vm._v(" 主頁 > ")])]),_c('div',[_c('router-link',{attrs:{"to":"/change"}},[_c('span',{staticClass:"label",style:({
                            color:
                                _vm.activeItemId === 2
                                    ? _vm.isFixed
                                        ? '#a73b47'
                                        : '#d94d5d'
                                    : _vm.isFixed
                                    ? '#fff'
                                    : '#999999'
                        }),on:{"click":function($event){return _vm.setActiveItem(2)}}},[_vm._v(" 找換TIPS ")])])],1),_c('div',[_c('router-link',{attrs:{"to":"/contact"}},[_c('span',{staticClass:"label",style:({
                            color:
                                _vm.activeItemId === 3
                                    ? _vm.isFixed
                                        ? '#a73b47'
                                        : '#d94d5d'
                                    : _vm.isFixed
                                    ? '#fff'
                                    : '#999999'
                        }),on:{"click":function($event){return _vm.setActiveItem(3)}}},[_vm._v(" 聯絡我們 ")])])],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.leright),expression:"!leright"}],staticClass:"right_box"},[_c('div',[_c('span',{staticClass:"label",style:({
                        color:
                            _vm.activeItemId === 1
                                ? _vm.isFixed
                                    ? '#a73b47'
                                    : '#d94d5d'
                                : _vm.isFixed
                                ? '#fff'
                                : '#999999'
                    }),on:{"click":_vm.moveToLeft}},[_vm._v(" < back ")])]),_c('div',[_c('router-link',{attrs:{"to":"/"}},[_c('span',{staticClass:"label",style:({
                            color:
                                _vm.activeItemId === 4
                                    ? _vm.isFixed
                                        ? '#a73b47'
                                        : '#d94d5d'
                                    : _vm.isFixed
                                    ? '#fff'
                                    : '#999999'
                        }),on:{"click":function($event){return _vm.setActiveItem(4)}}},[_vm._v(" 主頁 ")])])],1),_c('div',[_c('router-link',{attrs:{"to":"/exchange"}},[_c('span',{staticClass:"label",style:({
                            color:
                                _vm.activeItemId === 5
                                    ? _vm.isFixed
                                        ? '#a73b47'
                                        : '#d94d5d'
                                    : _vm.isFixed
                                    ? '#fff'
                                    : '#999999'
                        }),on:{"click":function($event){return _vm.setActiveItem(5)}}},[_vm._v(" 最新匯率 ")])])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }